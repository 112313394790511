import './AdminPage.scss';
// import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';

import Page from "./Page";
import Game from './lib/game';
import ReplayEntry from './components/ReplayEntry';

import songs from "./data/songs.json";


const TRACKING_ID = "G-QFXKCX4N3S";

function AdminPage() {

  let [gameStats, gameHistory] = Game.LoadGameData();

  let currentDay = songs.length;
  

  return ((currentDay > 0) ? (
    <Page>
      <div class="previous-days">
        {Array.from({length: currentDay}, (_, i) => {
          return <ReplayEntry day={currentDay - i} status={gameHistory[currentDay - i]} admin={true} />
        })}
      </div>
    </Page>
  ) : "");
}

export default AdminPage;
