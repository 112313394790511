import "./GuessBar.scss";
import Fuse from "fuse.js";
import { useState } from 'react';

import songs from '../data/songs.json';
import games from '../data/games.json';
import platforms from '../data/consoles.json';

export default function GuessBar(props) {

    const [results, setResults] = useState([]);
    const [isResultsVisible, setIsResultsVisible] = useState(false);
    const [selectedSong, setSelectedSong] = useState(null);
    const [manualUpdate, setManualUpdate] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);

    const OnGuess = props.OnGuess ?? ((guess) => {});
    const OnSkip = props.OnSkip ?? (() => {});

    const songData = songs.map(song => {
        const game = games.find(g => g.id === song.game);
        if(game === undefined) return {};
        const platform = platforms.find(p => p.acro === game.platform);
        return {
            name: game.name + " (" + platform.acro + ") - " + song.name,
            songName: song.name,
            gameName: game.name,
            aliases: (song.aliases ?? []),
            gameAliases: (game.aliases ?? []),
            franchises: game.franchises,
            gameId: game.id,
            songId: song.folder,
            platform: platform.name,
            platformAcro: platform.acro,
        };
    });

    function OnKeyDown(event) {
        if(event.keyCode === 40 && cursorPosition > 0) {
            setCursorPosition(cursorPosition - 1);
        }else if(event.keyCode === 38 && cursorPosition < results.length) {
            setCursorPosition(cursorPosition + 1);
        }else if(event.keyCode === 13 && cursorPosition > 0) {
            SelectSong(results[cursorPosition - 1]);
            const inputField = document.getElementById("guess-input");
            inputField.blur();
        }
    }

    function OnInputChanged(event) {
        const value = event.target.value;
        if (value.length < 3){
            setResults([]);
            return;
        }

        const fuse = new Fuse(songData, {
            keys: ['songName', 'name', 'aliases', 'gameAliases', 'franchises', 'platform', 'platformAcro'],
            includeScore: true,
            includeMatches: true,
        });

        // where score is less than 0.4
        setResults(fuse.search(value).filter(result => result.score < 0.2));
        setCursorPosition(0);
    }

    function OnGuessClick() {
        const input = document.getElementById("guess-input");
        if (selectedSong === null || input.value !== selectedSong.name) {
            return;
        }
        OnGuess(selectedSong.songId);
    }

    function OnInputFocus(event) {
        setIsResultsVisible(true);
    }

    function OnInputDefocus(event) {
        setTimeout(() => setIsResultsVisible(false), 200);
    }

    function GetHighlightArray(result) {
        const text = result.item.name
        const match = result.matches.find(m => m.key === 'name') ?? result.matches[0];
        const indices = match.indices
        const parts = [];
        let lastIndex = 0;
        indices.forEach(([start, end]) => {
            if (start > lastIndex) {
                parts.push({ text: text.slice(lastIndex, start), highlight: false });
            }
            parts.push({ text: text.slice(start, end + 1), highlight: true });
            lastIndex = end + 1;
        });
        if (lastIndex < text.length) {
            parts.push({ text: text.slice(lastIndex), highlight: false });
        }
        return parts;
    }

    function SelectSong(result) {
        const input = document.getElementById("guess-input");
        input.value = result.item.name;
        setSelectedSong(result.item);
    }

    return (
        <div className="guess-bar">
            <div className="guess-box">
                <input id="guess-input" type="text" placeholder="Type the name of the song or game" onInput={OnInputChanged} onKeyDown={OnKeyDown} onFocus={OnInputFocus} onBlur={OnInputDefocus} onChange={() => setManualUpdate(manualUpdate+1)} />
                {(isResultsVisible && results.length > 0) ? (
                    <div className="guess-results" id="guess-results">
                        {results.map((result, index) => (
                            <div className={"guess-result" + (((index + 1) === cursorPosition) ? " active" : "")} key={index} onClick={() => SelectSong(result)}>
                                {GetHighlightArray(result).map((part, ind) => (
                                    <span className={part.highlight ? "highlight" : ""} key={ind}>{part.text}</span>
                                ))}
                            </div>  
                        ))}
                    </div>
                ) : ""}
            </div>
            <div className="guess-controls">
                <div className="button skip" onClick={OnSkip}>Skip</div>
                <div className="button guess" onClick={OnGuessClick}>Guess</div>
            </div>
        </div>
    );
}