import { Icon } from "@iconify/react/dist/iconify.js";
import "./ReplayEntry.scss";
import Game from "../lib/game";

export default function ReplayEntry(props) {

    const status = props.status ?? null;
    const day = props.day ?? 0;
    const admin = props.admin ?? false;

    function GetState(){
        if(status !== null){
            if(status.win) return "✅";
            if(status.lose) return "❌";
        }
        return "⌛";
    }

    function GetEmojis(){
        if(admin) return Game.GetDateFromDay(day).toLocaleDateString();
        return Game.GetEmojis(status);
    }

    function GetName(){
        if(admin) return Game.GetFullSongName(Game.GetSongFromDay(day)[0]);
        if(status !== null){
            if(status.win){
                return Game.GetFullSongName(Game.GetSongFromDay(day)[0]);
            }
            if(status.lose){
                return "Failed";
            }
        }
        return "Unplayed";
    }

    function GetConsoleImage(){
        var song = Game.GetSongFromDay(day)[0];
        var game = Game.GetGame(song);
        var console = Game.GetConsole(game);
        return require("../ui/platforms/" + console.image);
    }

    function GetAnswerStyle(){
        if(admin) return "correct admin";
        if(status === null) return "";
        if(status.win) return "correct";
        if(status.win === false) return "incorrect";
        return "";
    }

    return ((day > 0) ? (
        <div className="replay-entry">
            <a href={"/?day=" + day}>
                <div className="button">Play Day #{day}</div>
            </a>
            <div className="emojis">{GetEmojis(status)}</div>
            <div className="state">{GetState()}</div>
            <div className={"answer " + GetAnswerStyle()}>
                <div className="wrapper">
                    <span className="game-name">{GetName()}</span>
                    <span className="space" />
                    <span className="game-name">{GetName()}</span>
                    <span className="space" />
                </div>
            </div>
            {admin ? (
                <img src={GetConsoleImage()} />
            ) : ""}
        </div>
    ) : "");
}