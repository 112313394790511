import { Icon } from "@iconify/react/dist/iconify.js";
import "./Popup.scss";
import React from 'react'

function Popup(props) {

    function OnCloseButtonClick() {
        props.setTrigger(false);
    } 

    return (
        <div className={"popup" + (props.trigger ? "" : " closed")}>
            <div className="popup-content">
                <div className="close-button" onClick={OnCloseButtonClick}>
                    <Icon icon="ph:x-circle" width="32" height="32" />
                </div>
                { props.children }
            </div>
        </div>
    );
}

export default Popup