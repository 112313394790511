import Game from "../../lib/game";
import "./Stats.scss";

export default function Stats(props) {

    const stats = props.stats ?? {};
    const chart = [];
    for(let i=0; i<6; i++){
        chart.push(stats["guess" + (i + 1).toString()] ?? 0);
    }
    chart.push(stats.lost ?? 0);

    function GetChartFill(value){
        if(value === 0) return 0;
        return (value / Math.max(...chart)) * 100;
    }

    return (
        <div className="stats-panel">
            <h3>Statistics</h3>
            <div className="chart">
                {chart.map((value, index) => (
                    <div className="chart-entry" key={index}>
                        <div className="chart-number">{(index == 6) ? "💀" : (index + 1)}</div>
                        <div className="chart-bar">
                            <div className="chart-fill" style={{ width: GetChartFill(value) + "%" }}>{value}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="info">
                <div className="info-entry">
                    <div className="info-value">{Game.GetFound(stats) + "/" + Game.GetTotalPlayed(stats)}</div>
                    <div className="info-value smaller">{"(" + (Game.GetFound(stats) / Game.GetTotalPlayed(stats) * 100.0).toPrecision(4) + "%)"}</div>
                    <div className="info-title">Correct Guesses</div>
                </div>
                <div className="info-entry">
                    <div className="info-value bigger">{stats.currentStreak}</div>
                    <div className="info-title">Current Streak</div>
                </div>
                <div className="info-entry">
                    <div className="info-value bigger">{stats.maxStreak}</div>
                    <div className="info-title">Max Streak</div>
                </div>
            </div>
        </div>
    );
}