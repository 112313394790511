import Stats from "./Popups/Stats";
import TimeToNext from "./TimeToNext";
import { Icon } from "@iconify/react/dist/iconify.js";
import Game from "../lib/game";
import "./Results.scss";

import games from "../data/games.json";
import platforms from "../data/consoles.json";

export default function Results(props) {

    const didWin = props.didWin ?? false;
    const song = props.song ?? undefined;
    const stats = props.stats ?? {};

    const OnShareClick = props.OnShareClick ?? (() => { });

    return (
        <div className="results-panel">
            {didWin ? (
                <p>You got it! Today's VGBandle was:</p>
            ) : (
                <p>Better luck tomorrow! Today's VGBandle was:</p>
            )}
            <p className="result-title">{Game.GetFullSongName(song)}</p>
            <iframe className="result-video" width="100%" src={"https://www.youtube.com/embed/" + song.youtube} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <Stats stats={stats} />
            <div className="options">
                <a href="/previous">     
                    <div className="button" onClick={OnShareClick}>
                        <Icon icon="ph:skip-back" width="32" height="32" />
                        Play Previous Days
                    </div>
                </a>
                <div className="button" onClick={OnShareClick}>
                    <Icon icon="ph:share-network" width="32" height="32" />
                    Share
                </div>
            </div>
            <div className="time-until-next">
                <div className="title">Next VGBandle In:</div>
                <TimeToNext />
            </div>
        </div>
    );
}