import "./Header.scss";
import { Icon } from '@iconify/react';

export default function Header(props) {

    const OnShareClick = props.OnShareClick ?? (() => { });
    const OnHelpClick = props.OnHelpClick ?? (() => { });
    const OnStatsClick = props.OnStatsClick ?? (() => { });

    return (
        <div className="app-header">
            <header>
                <div className="options">
                    <Icon icon="ph:gear-light" width="32" height="32" />
                    <Icon icon="ph:share-network" width="32" height="32" onClick={OnShareClick} />
                </div>
                <a href="/">
                    <div className="logo">🎮 Video Game Bandle 🎸</div>
                </a>
                <div className="options">
                    <Icon icon="ph:chart-bar" width="32" height="32" onClick={OnStatsClick} />
                    <Icon icon="ph:question" width="32" height="32" onClick={OnHelpClick} />
                </div>
            </header>
            <div className="line"></div>
        </div>
    );
}