import './PreviousGames.scss';
// import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';

import Page from "./Page";
import Game from './lib/game';
import ReplayEntry from './components/ReplayEntry';


const TRACKING_ID = "G-QFXKCX4N3S";

function PreviousGames() {

  let [gameStats, gameHistory] = Game.LoadGameData();

  // Game Variables
  const [currentDay, setCurrentDay] = useState(0);

  useEffect(() => {
    Game.GetDailySong(0, (song, game, unixtime) => {
      let day = Game.GetBandleDay(unixtime);
      setCurrentDay(day);
    });
  }, []);
  

  return ((currentDay > 0) ? (
    <Page>
      <div class="previous-days">
        {Array.from({length: currentDay}, (_, i) => {
          return <ReplayEntry day={currentDay - i} status={gameHistory[currentDay - i]} />
        })}
      </div>
    </Page>
  ) : "");
}

export default PreviousGames;
