import Game from "../lib/game";
import { useState, useEffect } from "react";

export default function TimeToNext() {

    const [time, setTime] = useState(Game.GetTimeToNext());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Game.GetTimeToNext());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="time">
            {time}
        </div>
    );
}