import songs from "../data/songs.json";
import games from "../data/games.json";
import consoles from "../data/consoles.json";
import ls from "localstorage-ttl";

function GetSong(id) {
    return songs.find(song => song.folder === id);
}

function GetGame(song) {
    return games.find(game => game.id === song.game);
}

function GetConsole(game) {
    return consoles.find(console => console.acro === game.platform);
}

function GetDay(unixtime) {
    return Math.floor(unixtime / 1000 / 60 / 60 / 24);
}

function FetchUnixTime(callback) {
    callback ??= ((_) => {});
    fetch("https://worldtimeapi.org/api/ip")
    .then(response => response.json())
    .then(data => {
        const unixtime = data.unixtime * 1000;
        ls.set("unix", unixtime, 30000);
        console.log(unixtime);
        callback(unixtime);
    });
}

function GetFullSongName(song) {
    if(typeof song === "string" || song instanceof String){
        song = GetSong(song);
    }
    if(!song) return "";
    const game = games.find(g => g.id === song.game);
    const platform = consoles.find(p => p.acro === game.platform);
    return game.name + " (" + platform.acro + ") - " + song.name;
}

function GetBandleDay(unixtime) {
    return GetDay(unixtime) - 19882;
}

function GetDateFromDay(day){
    return new Date((day + 19882 + 1) * 24 * 60 * 60 * 1000);
}

function GetDailySong(proposedDay, callback) {
    const unixtime = ls.get("unix") ?? 0;

    function Finalize(unixtime){
        let day = GetBandleDay(unixtime);
        if(proposedDay > 0 && (proposedDay <= day || process.env.NODE_ENV === "development")){
            day = proposedDay;
        }
        const [song, game] = GetSongFromDay(day);
        callback(song, game, unixtime);
    }

    if(unixtime == 0 || Math.abs(Date.now() - unixtime) > 60000){
        console.log("fetching unix time");
        FetchUnixTime(Finalize);
    }else{
        console.log("using stored unix time");
        Finalize(unixtime);
    }
}

function GetSongFromDay(day) {
    const songIndex = day % songs.length;
    const song = songs[Math.floor(songIndex)];
    const game = GetGame(song);
    return [song, game];
}

function GetTimeToNext() {
    // Returns a 00:00:00 formatted string
    const now = Date.now()
    const nextDay = Math.floor((now + (24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000);
    const diff = Math.floor(nextDay - now);
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) % 60;
    const seconds = Math.floor(diff / 1000) % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function LoadGameData() {
    let gameStats;
    try {
        gameStats = JSON.parse(localStorage.getItem("statistics")) ?? GetDefaultStats();
    }catch{
        gameStats = GetDefaultStats();
    }
    let gameHistory;
    try {
        gameHistory = JSON.parse(localStorage.getItem("history")) ?? GetDefaultHistory();
    }catch {
        gameHistory = GetDefaultHistory();
    }

    return [gameStats, gameHistory];
}

function GetDefaultStats(unixtime) {
    unixtime ??= Date.now();
    return {
        played: 0,
        currentStreak: 0,
        maxStreak: 0,
        guess1: 0,
        guess2: 0,
        guess3: 0,
        guess4: 0,
        guess5: 0,
        guess6: 0,
        lost: 0
    };
}
  
function GetDefaultHistory() {
    return {};
}

function GetDefaultStatus(givenDay) {
    return {
        day: givenDay,
        step: 0,
        win: false,
        lose: false,
        guesses: [],
        hasYear: [],
        hasConsole: [],
        hasSeries: [],
        franchises: []
    };
}

function GetFound(stats){
    return stats.guess1 + stats.guess2 + stats.guess3 + stats.guess4 + stats.guess5 + stats.guess6;
}

function GetTotalPlayed(stats){
    return GetFound(stats) + stats.lost;
}

function GetEmojis(gameStatus){
    if(gameStatus === null){
        return "❓❓❓❓❓❓";
    }

    let emojis = "";
    for(let i = 0; i < 6; i++){
        if(i == gameStatus.step && gameStatus.win){
            emojis += "🟩";
        }else if(i < gameStatus.guesses.length && (
            gameStatus.hasYear.includes(i) ||
            gameStatus.hasConsole.includes(i) ||
            gameStatus.hasSeries.includes(i)
        )){
            emojis += "🟨";
        }else if(i < gameStatus.guesses.length && gameStatus.guesses[i] !== ""){
            emojis += "🟥";
        }else if(!gameStatus.win && !gameStatus.lose && i >= gameStatus.step){
            emojis += "❓";
        }else{
            emojis += "⬛";
        }
    }
    return emojis;
}

function ShareGame(gameStatus, gameStats){

    if(!gameStatus.win && !gameStatus.lose){
        const emptyMessage = "New VGBandle Game\nCan you guess the song played by the MIDI band?\nhttps://vgbandle.app/";
        navigator.clipboard.writeText(emptyMessage);
        return;
    }

    const guesses = (gameStatus.lose) ? "X" : (gameStatus.step + 1);
    let str = "VGBandle #" + gameStatus.day + " " + guesses + "/6\n";
    str += GetEmojis(gameStatus) + "\n";
    str += "Found: " + GetFound(gameStats) + "/" + gameStats.played + " (" + (GetFound(gameStats) / gameStats.played * 100.0) + "%)\n";
    str += "Current Streak: " + gameStats.currentStreak + " (max " + gameStats.maxStreak + ")\n";
    str += "#VGBandle #Bandle #Wordle"
    str += "\n\n";
    str += "https://vgbandle.app/";

    navigator.clipboard.writeText(str);
}

export default {
    GetSong,
    GetGame,
    GetConsole,
    GetFullSongName,
    GetDay,
    GetBandleDay,
    GetTimeToNext,
    GetDailySong,
    GetSongFromDay,
    LoadGameData,
    GetDefaultStats,
    GetDefaultStatus,
    GetFound,
    GetTotalPlayed,
    GetDateFromDay,
    GetEmojis,
    ShareGame
};
