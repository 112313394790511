import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PreviousGames from './PreviousGames';
import AdminPage from './AdminPage';

const pages = [
    {
        path: "/",
        element: <App />
    },
    {
        path: "/previous",
        element: <PreviousGames />
    }
];
if(process.env.NODE_ENV === "development"){
    pages.push({
        path: "/admin",
        element: <AdminPage />
    });
}
const router = createBrowserRouter(pages);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
    <RouterProvider router={router} />   
</React.StrictMode>);

reportWebVitals(sendToVercelAnalytics);
