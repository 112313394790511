import "./HintPanel.scss";

import consoles from "../data/consoles.json";

function GetConsole(game) {
    return consoles.find(console => console.acro === game.platform);
}

function GetDifficultyName(par){
    switch(par){
        case 1: return "Easy";
        case 2: return "Medium";
        case 3: return "Challenging";
        case 4: return "Hard";
        case 5: return "Expert";
        default: return "Unknown";
    }
}

function GetFranchises(game, franchises, showInfo){
    const gameFranchises = game.franchises;
    const myFranchises = franchises;

    if(showInfo) return gameFranchises.join(", ");

    let newFranchises = [];
    let hasAny = false;
    for(let i = 0; i < gameFranchises.length; i++){
        if(myFranchises.includes(gameFranchises[i])){
            newFranchises.push(gameFranchises[i]);
            hasAny = true;
        }
    }
    if(!hasAny) return "????";
    let toReturn = newFranchises.join(", ");
    if(newFranchises.length < gameFranchises.length) toReturn += ", ????";
    return toReturn;
}

export default function HintPanel(props) {

    const song = props.song;
    const game = props.game;
    const console = GetConsole(game);

    const showInfo = props.showInfo ?? 0;
    const hasYear = props.hasYear ?? false;
    const hasConsole = props.hasConsole ?? false;
    const hasFranchises = props.hasFranchises ?? [];

    return (song != null && game != null)  ? (
        <div className="hint-panel">

            <div className="hint-entry">
                <div className="hint-title">Released in:</div>
                <div className="hint-value">{(hasYear || showInfo) ? game.released : "????"}</div>
            </div>

            <div className="hint-entry">
                <div className="hint-title">Console:</div>
                <div className="hint-value">
                    {
                    (hasConsole || showInfo) ?
                    (
                        <div className="console">
                            <div className="console-name">{console.name + " (" + console.acro + ")"}</div>
                            <img className="console-image" src={require("../ui/platforms/" + console.image)} alt={console.name} />
                        </div>
                    ) : "????"
                    }
                </div>
            </div>

            <div className="hint-entry">
                <div className="hint-title">Series:</div>
                <div className="hint-value">{GetFranchises(game, hasFranchises, showInfo)}</div>
            </div>
            
            <div className="hint-entry">
                <div className="hint-title">Difficulty:</div>
                <div className="hint-value">{GetDifficultyName(song.par) + " (par " + song.par + ")" }</div>
            </div>

        </div>
    ) : (
        <div className="hint-panel">
            Error! No song supplied!
        </div>
    );
}