import { Icon } from "@iconify/react/dist/iconify.js";
import "./HowToPlay.scss";

export default function HowToPlay(props) {

    const OnPlayClicked = props.OnPlayClicked ?? (() => {});

    return (
        <div className="how-to-play">
            <h3>How to play</h3>

            <div className="section">
                <Icon icon="ph:music-notes-simple" width="32" height="32" />
                <p>Our MIDI band will play some Video Game Music for you. But the musicians join in one-by-one.</p>
            </div>

            <div className="section">
                <Icon icon="ph:chat-dots" width="32" height="32" />
                <p>Your goal is to guess both the game that the song is from and the name of the song.</p>
            </div>

            <div className="section">
                <Icon icon="ph:question" width="32" height="32" />
                <p>You only get 6 guesses, but don't worry if you don't know it! Guessing games in the same year/console/series will reveal that information about the game.</p>
            </div>

            {/* <div className="section">
                <Icon icon="ph:twitter-logo" width="32" height="32" />
                <p>Share your results with your friends! Press the Share Button when you're done to show the world how good a guesser you are.</p>
            </div> */}

            <p>This project is created and maintained by <a href="https://carsonk.net/">@CarsonKompon</a> and is not affiliated with the original <a href="https://bandle.app/">Bandle</a>. Check out the original for non-video game music :)</p>
        
            <div className="buttons">
                <a href="https://discord.gg/yKVZgC8z7S" target="_blank">
                    <div className="button discord">
                        <Icon icon="prime:discord" width="24" height="24" />
                        Discord
                    </div>
                </a>
                <div className="button" onClick={OnPlayClicked}>Play</div>
            </div>
        </div>
    );
}