import useSound from "use-sound";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import Game from "../lib/game";
import "./TrackPlayer.scss";

import songs from "../data/songs.json";
import games from "../data/games.json";
import consoles from "../data/consoles.json";

export default function TrackPlayer(props) {

    const [stepPlaying, setStepPlaying] = useState(-1);
    const stopPlaying = props.stopPlaying ?? -1;
    const setStopPlaying = props.setStopPlaying ?? ((_) => {});

    const song = props.song ?? undefined;
    const currentStep = props.step ?? 0;
    const trackFolder = song?.folder ?? "unknown";
    const hasFinished = props.hasFinished ?? false;
    const canGuess = props.canGuess ?? false;
    const setCanGuess = props.setCanGuess ?? ((_) => {});
    if(song.steps.length < 6) song.steps.push("Reveal Info");

    const hasYear = props.hasYear ?? [];
    const hasConsole = props.hasConsole ?? [];
    const hasSeries = props.hasSeries ?? [];
    const guesses = props.guesses ?? [];

    const playTrack = [];
    const stopTrack = [];
    [playTrack[0], {stop: stopTrack[0]}] = useSound(GetTrackPath(0), {volume: 0.5, interrupt: true, onend: () => setStepPlaying(-1)});
    [playTrack[1], {stop: stopTrack[1]}] = useSound(GetTrackPath(1), {volume: 0.5, interrupt: true, onend: () => setStepPlaying(-1)});
    [playTrack[2], {stop: stopTrack[2]}] = useSound(GetTrackPath(2), {volume: 0.5, interrupt: true, onend: () => setStepPlaying(-1)});
    [playTrack[3], {stop: stopTrack[3]}] = useSound(GetTrackPath(3), {volume: 0.5, interrupt: true, onend: () => setStepPlaying(-1)});
    [playTrack[4], {stop: stopTrack[4]}] = useSound(GetTrackPath(4), {volume: 0.5, interrupt: true, onend: () => setStepPlaying(-1)});

    if(stopPlaying !== -1){
        if(stepPlaying !== -1){
            PlayStep(stopPlaying);
        }
        setStopPlaying(-1);
    }

    function GetTrackPath(track) {
        const path = `tracks/${trackFolder}/track_0${track + 1}.mp3`;
        return require("../" + path);
    }

    function PlayStep(step) {
        step = Math.min(step, 4);

        if(stepPlaying === step){
            stopTrack[stepPlaying]();
            setStepPlaying(-1);
            return;
        }

        if(stepPlaying !== -1){
            stopTrack[stepPlaying]();
        }

        playTrack[step]();
        setStepPlaying(step);
        setCanGuess(true);
    }

    return song ? (
        <div className="track-player">
            {song.steps.map((step, index) => (
                <div className={"track" + (index === currentStep ? " current" : "") + (index < currentStep ? " missed" : "")} key={index}>
                    <div className="track-title">{index + 1} - {step}</div>
                    <div className="end-content">
                        <div className="icons">
                            {(hasYear.includes(index) ? <div className="icon" data-tooltip-id="my-tooltip" data-tooltip-content={"Your guess \"" + Game.GetFullSongName(guesses[index]) + "\" came out in the same Year"} data-tooltip-place="top">📅</div> : "")}
                            {(hasConsole.includes(index) ? <div className="icon" tooltip="gamer" data-tooltip-id="my-tooltip" data-tooltip-content={"Your guess \"" + Game.GetFullSongName(guesses[index]) + "\" is on the same Console"} data-tooltip-place="top">🎮</div> : "")}
                            {(hasSeries.includes(index) ? <div className="icon" data-tooltip-id="my-tooltip" data-tooltip-content={"Your guess \"" + Game.GetFullSongName(guesses[index]) + "\" is in the same Series/Franchise"} data-tooltip-place="top">🎭</div> : "")}
                        </div>
                        {(hasFinished && index < 5) ? (
                            <div className="play-button" onClick={() => PlayStep(index)}>
                                <Icon icon={stepPlaying === index ? "ph:stop" : "ph:play"} width="18" height="18" />
                            </div>
                        ) : ""}
                    </div>
                </div>
            ))}
            {(!hasFinished) ? (
                <div className="play-button" onClick={() => PlayStep(currentStep)}>
                    <Icon icon={stepPlaying === -1 ? "ph:play" : "ph:stop"} width="32" height="32" />
                    {(currentStep === 0 && !canGuess) ? (
                        <div className="play-button-tooltip">Turn up the volume and press Play</div>
                    ) : ""}
                </div>
            ) : ""}
            <Tooltip id="my-tooltip" />
        </div>
    ) : "";
}